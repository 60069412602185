<template>
  <div class="book-sort-tree">
    <div class="book-sort-tree-wrapper">
      <div class="book-sort-tree-header">
        <r-title type="subtitle-2">
          {{ $t('book-sort-tree:' + 'book-sorting:title') }}
        </r-title>
        <r-button
          simple
          type="primary"
          @click="beforeToggleAttributes()"
        >
          {{ toggleName }}
        </r-button>
      </div>
      <r-search-input
        :filter-text="filterText"
        @change="searchChange"
      />
      <ul
        v-if="columns && columns.length"
        class="book-sort-tree-list"
      >
        <li
          v-for="(i, n) in columns"
          :key="n"
          class="book-sort-tree-item"
        >
          <el-checkbox
            class="r-checkbox"
            size="mini"
            :value="isActive(i)"
            @change="beforeToggleAttributes(i)"
          />
          <div
            class="book-sort-tree-item-button"
            @click="beforeToggleAttributes(i)"
          >
            <r-text>
              {{ getItemTitle(i) }}
            </r-text>
            <r-text type="caption">
              {{ i.type }}
            </r-text>
          </div>
        </li>
      </ul>
      <div
        v-else
        class="book-sort-tree__no-data"
      >
        <r-text>
          {{
            !!filterText
              ? 'Согласно фильтру данные отсутствуют'
              : 'Данные отсутствуют'
          }}
        </r-text>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      savingAttributes: false,
      filterText: ''
    }
  },
  computed: {
    toggleName() {
      return this.activeFields.length
        ? this.$t('book-sort-tree:' + 'book-sorting:clear-all')
        : this.$t('book-sort-tree:' + 'book-sorting:apply-all')
    },
    activeBook() {
      return this.$store.state.book.activeBook || {}
    },
    activeBookId() {
      return this.activeBook.id || null
    },
    columns() {
      const active = this.activeFields?.map(e => e.title)
      const query = this.filterText?.trim()?.toLowerCase()
      const queryAttrs = ['alias', 'title', 'origin_title', 'reflection.alias', 'reflection.origin_title', 'type']

      const filtered = !query ? this.activeBook.fields : this.activeBook.fields?.filter(
        e => {
          const filtered = queryAttrs
            .map(f => {
              if (f.includes('.')) {
                const [first, second] = f.split('.')

                return e[first]?.[second]
              } else {
                return e[f]
              }
            })
            .join('')
            ?.trim()
            .toLowerCase()

          return filtered.includes(query)
        }
      )

      return filtered?.sort(e => {
        if (active?.includes(e.title)) return -1
        else return 1
      }) || null
    },
    activeFields() {
      return this.$store.state.book.activeFields?.fields || []
    },
    changedRows() {
      return this.$store.state.tablePrime.changedRows.book_table || null
    },
    hasChanges() {
      if (!this.changedRows) return false
      return !!Object.values(this.changedRows)?.length
    }
  },
  methods: {
    getItemTitle(item) {
      return item.alias || item.title
    },
    searchChange(e) {
      this.filterText = e
    },
    isActive(object) {
      const active = this.activeFields?.map(e => e.title)

      return active?.includes(object.title)
    },
    beforeToggleAttributes(object) {
      if (this.hasChanges) {
        const title = 'Предупреждение'
        const message = 'Все изменения будут отменены. Продолжить?'
        const confirm = 'Подтвердить'
        const cancel = 'Отмена'
        this.$confirm(message, title, {
          customClass: 'r-message-box',
          type: 'warning',
          closeOnPressEscape: false,
          closeOnClickModal: false,
          confirm,
          cancel
        })
          .then(() => {
            if (!object) {
              this.toggleAll()
            } else {
              this.clickHandler(object)
            }
          })
          .catch(() => {})
      } else {
        if (!object) {
          this.toggleAll()
        } else {
          this.clickHandler(object)
        }
      }
    },
    clickHandler(object) {
      const index = this.activeFields.findIndex(
        item => item.title === object.title
      )

      if (index > -1) {
        this.activeFields.splice(index, 1)
      } else {
        this.activeFields.push(object)
      }
      this.saveSortFields()
      this.$store.commit('BOOK_SET_FIELD', {
        field: 'updateFields',
        value: true
      })
    },
    toggleAll() {
      this.$store.commit('BOOK_TOGGLE_ACTIVE_FIELDS')
      this.saveSortFields()
      this.$store.commit('BOOK_SET_FIELD', {
        field: 'updateFields',
        value: true
      })
    },
    async saveSortFields() {
      try {
        this.$store.commit('BOOK_SET_IS_LOADING', true)
        this.$store.commit('SET_ACTIVE_FIELDS', {
          fields: this.activeFields,
          update: false
        })
        await this.$store.dispatch('SAVE_MODULE_USER_CONFIG', {
          module: 'book',
          id: this.activeBookId
        })
      } catch (e) {
        this.$store.commit('BOOK_SET_IS_LOADING', false)
        throw new Error(e)
      }
    }
  }
}
</script>

<style lang="scss">
.book-sort-tree {
  position: relative;
  padding: 8px;
  > div {
    &:nth-child(2) {
      margin-top: 16px;
    }
  }
  &-wrapper {
    padding: 0 0 8px;
    position: relative;
  }
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-list {
    margin-top: 16px;
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
  }
  &-item {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: var(--border-radius);
    margin-bottom: 4px;
    &:last-child {
      margin-bottom: 0;
    }
    &-button {
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      * {
        margin-bottom: 2px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .r-text,
    .r-text.caption-2 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
    &:hover {
      .book__popover-tools {
        display: flex;
      }
    }
  }
  &-tools {
    display: none;
    align-items: center;
    height: 100%;
    &-item {
      display: flex;
      align-items: center;
      border-radius: var(--border-radius);
      opacity: 1;
      cursor: pointer;
      margin-right: 4px;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
  &__no-data {
    margin-top: 12px;
    display: flex;
    justify-content: center;
  }
}
</style>
